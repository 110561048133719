@import "../../frontend/legl-ui/lds-colours/legacy/scss/legl-variables.scss";

#sidebar {
  font-family: "Lato", sans-serif;
  background: #ffffff;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  z-index: 140;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  width: 23rem;
  overflow: auto;
  transition: 0.5s;
}

#open-sidebar {
  margin-left: $spacing-s;
  text-decoration: none;
  display: inline-block;
  transition: 0.3s;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 100%;
  color: var(--lds-colour-neutral-700);
  cursor: pointer;
}

#close-button {
  right: 5px;
  padding: 7px 8px;
  text-decoration: none;
  display: inline-block;
  transition: 0.3s;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 167.5%;
  cursor: pointer;
}

#close-button span {
  font-size: 35px;
  margin-left: 5px;
  vertical-align: bottom;
}

#sidebar #office-photo-container {
  padding-top: 2em;
  display: flex;
  justify-content: center;
  border-radius: 4px;
}

#sidebar #office-photo {
  width: 90%;
  border-radius: 6px;
}

#sidebar #company-contact-details {
  display: flex;
  padding: 1.4em 1em;
  justify-content: space-evenly;
  word-break: break-word;
  grid-template-columns: auto auto;
  grid-column-gap: 20px;
  grid-template-rows: auto;
  font-size: 0.8em;
}

#sidebar #company-contact-details lds-icon {
  color: var(--lds-colour-neutral-700);
}

#sidebar #company-contact-details a {
  border: 1.5px solid #dadada;
  border-radius: 15px;
  padding: 1em;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  align-self: flex-start;
  flex: 1;
}

#sidebar #company-contact-details a:last-child {
  margin-left: 0.75rem;
}

#sidebar #company-contact-details a i {
  font-size: 1.2rem;
  margin-bottom: 0.4rem;
}

#sidebar #company-contact-details p {
  color: var(--lds-colour-neutral-800);
  margin-bottom: 0;
}

#sidebar #company-details {
  text-align: center;
  font-family: "Lato", sans-serif;
  font-size: 1em;
  padding: 1em;
  color: var(--lds-colour-neutral-800);
}

#sidebar #company-details .company-name {
  font-size: 1.25rem;
  font-weight: bolder;
  padding: 0 0 0.5em 0;
}

#sidebar a {
  color: inherit;
  text-decoration: none;
}

#sidebar #company-details .link {
  padding-top: 1em;
  text-decoration: underline;
}

#sidebar #company-details > p {
  padding: 0.25em;
  margin: 0;
}

#sidebar .website-document-links {
  color: var(--lds-colour-neutral-800);
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 1rem 0;
}

#sidebar .website-document-links .dot-separator {
  font-size: 0.5em;
  align-self: center;
  padding: 0 1em;
}

#sidebar #bar-footer {
  left: 0;
  bottom: 0;
  width: 100%;
  margin-top: auto;
}

.open-sidebar {
  width: 368px !important;
}

.close-button {
  width: 0;
}

.lawfirm-sidebar-contact {
  margin-top: 13px;
}

[data-company-logo-container] {
  display: none;
}

[data-company-logo-wrapper] {
  display: flex;
  display: -webkit-flexbox;
}

[data-company-logo-wrapper] [data-company-logo-container] {
  background-color: transparent;
  text-align: center;
  padding: 1.5rem;
  display: flex;
  display: -webkit-flexbox;
  justify-content: center;
  margin: auto;
}

[data-company-logo-wrapper] [data-company-logo-container] [data-company-logo] {
  width: 100%;
  object-fit: contain;
}

[data-company-logo] {
  max-height: 100px;
}

[data-company-office-wrapper] {
  display: flex;
  display: -webkit-flexbox;
}

[data-company-office-wrapper] [data-company-office-container] {
  background-color: transparent;
  text-align: center;
  padding: 0 1rem;
  display: flex;
  display: -webkit-flexbox;
  justify-content: center;
  margin: auto;
}

[data-company-office-wrapper]
  [data-company-office-container]
  [data-company-office] {
  width: 100%;
  object-fit: contain;
  border-radius: 8px;
}

[data-verified-symbols-container] {
  background-color: white;
  display: flex;
  justify-content: center;
  padding: 0.5em;
  align-self: end;
}

[data-verified-symbols-container] img {
  padding: 0 var(--lds-spacing-xs);
}

@media screen and (max-width: 425px) {
  .open-sidebar {
    width: 100% !important;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  [data-company-office-wrapper] [data-company-office-container] {
    display: -webkit-box;
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  #sidebar {
    display: flex;
    flex-direction: column;
    width: 0;
  }

  [data-company-logo-wrapper] {
    display: -webkit-box;
  }
  
  [data-company-logo-wrapper]
    [data-company-logo-container]
    [data-company-logo] {
    max-width: 100%;
  }

  #open-sidebar {
    margin-left: $spacing-xs;
  }
}

@media screen and (min-width: $tablet-breakpoint) {
  #sidebar {
    width: 368px !important;
    max-width: 368px !important;
  }
}

@media screen and (max-height: 700px) {
  [data-company-office-wrapper] {
    display: none;
  }
}

[data-display-only-on-mobile] {
  display: none;
}

@media only screen and (max-width: 1029px) {
  [data-display-only-on-mobile] {
    display: block;
  }

  [data-do-not-display-on-mobile] {
    display: none;
  }
}
