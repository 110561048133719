@import "./bootstrap_variables.scss";
$brand-primary: #29d273;
@import "./colors";
$tablet-breakpoint: 1030px;

/*Spacing*/
$spacing-xxs: 4px;
$spacing-xs: 8px;
$spacing-s: 16px;
$spacing-m: 24px;
$spacing-l: 32px;
$spacing-xl: 40px;

:root {
  /*Colours*/
  --legl-brand-grey: #{$brand-grey};
  --legl-grey-dark: #{$legl-grey-dark};
  --legl-grey-active: #{$legl-grey-active};
  --legl-system: #{$legl-system};
  --legl-grey-light: #{$legl-grey-light};
  --legl-grey-lightest: #{$gray-lightest};
  --legl-grey-verylight: #{$brand-grey};
  --legl-grey-disabled: #{$legl-grey-disabled};
  --legl-state-success: #{$state-success};
  --legl-state-success-hover: #{$legl-green-dark};
  --legl-red: #{$legl-red};
  --legl-red-light: #E5194B;
  --legl-red-dark: #8A122F;
  --legl-yellow: #{$legl-yellow};
  --legl-primary-cta: #{$legl-primary-cta};
  --legl-grey-medium: #{$brand-grey};
  --legl-grey-100: #{$brand-grey};
  --legl-green: #01b88e;
  --legl-blue: #4A70FA;
  --legl-navy: #{$legl-navy};
  --legl-dark-purple: #{$legl-system};
  --legl-grey-background: #{$background-grey};
  --legl-grey-borders: #{$legl-grey-borders};
  --legl-grey: #{$legl-grey};
  --legl-gun-powder: #{$gun-powder};
  --legl-white: #{$legl-white};

  /*Animation*/
  --legl-default-transition-duration: 0.25s;

  /*Responsive*/
  --legl-tablet-breakpoint: #{$tablet-breakpoint};
}
