@import "../../../../legl-ui/lds-colours/legacy/scss/legl-variables.scss";

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

input:not(.form-control),
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 16px;
  line-height: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

body {
  margin: 0;
  /* Remove default margin. */
  max-width: 100%;
  overflow-x: hidden;
  font-family: "Lato", sans-serif;
  background-color: var(--lds-colour-neutral-100);
  color: var(--lds-colour-neutral-800);
}

.hide {
  display: none;
}

.payments__wrapper main[role="main"] {
  background-color: #fbfafa;
}

.payment-title {
  text-align: center;
  font-family: var(--lds-typography-family-sans);
  font-weight: var(--lds-typography-weight-bold);
  font-size: var(--lds-typography-font-size-h1);
  line-height: var(--lds-typography-line-height-h1);
  padding: 1.1rem 0.5rem 1.5rem 0.5rem;
}

.layout-container {
  display: grid;
  grid-template-areas:
    "secured-message"
    "content";
  grid-template-rows: 3% auto;
  grid-template-columns: 1fr;
  height: 100vh;
  position: relative;
  margin-left: 23rem;
  opacity: 1;
}

@media screen and (min-width: 990px) {
  .layout-container {
    opacity: 1 !important;
  }
}

#payment-error-message {
  display: none;
  position: absolute;
  background-color: #f5e0dd;
  width: 100%;
}

#payment-error-message .text-container {
  align-self: center;
  padding: 0 0 0 1em;
}

#payment-error-message .content {
  padding: 1em;
  display: flex;
  justify-content: center;
}

#payment-error-message .content .error-heading {
  font-weight: bold;
  font-style: italic;
}

#payment-error-message .content .error-text {
  font-style: italic;
}

#secured-message {
  grid-area: secured-message;
  padding: 0 0.5rem;
  font-family: "Lato", sans-serif;
  font-style: italic;
  font-weight: 600;
  font-size: 0.9em;
  text-align: right;
  color: var(--lds-colour-neutral-700);
  background-color: transparent;
  align-self: center;
  display: flex;
  justify-self: end;
  z-index: -1;
}

#secured-message>img {
  padding: 0 0.5em 0 0;
  align-self: flex-end;
}

#payments-container {
  grid-area: content;
  height: 100%;
  background-color: var(--lds-colour-neutral-200);
}

main [data-payment-container] {
  padding: 5rem 5rem 0 5rem;
}

.content-container .content {
  padding: 0 10em;
}

#payments-form-container {
  background-color: blue;
  padding: 1em 4em 1em 4em;
}

.payment-item-container {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  text-align: center;
  width: 90%;
  margin: 48px auto 48px auto;
  position: relative;
  max-width: 754px;
}

.payment-item-container [data-payment-method-overlay] {
  display: none;
  opacity: 0;
  background-color: rgba(2, 0, 0, 0.78);
  z-index: -1;
  width: 100%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 0;
  height: 100%;
  min-height: 350px;
  border-radius: 4px;
  transition: opacity 0.5s 0.5s;

  &.show-now {
    display: block;
    opacity: 1;
    z-index: 2;
  }
}

[data-payment-method-overlay].show-now [data-terms-and-conditions-container] {
  z-index: 3;
  color: white;
  padding: 4em 4em;
  font-size: 1.3em;
}

@media screen and (max-width: 425px) {
  [data-payment-method-overlay].show-now [data-terms-and-conditions-container] {
    padding: 1em 1em;
  }
}

[data-terms-and-conditions-container] {
  flex-direction: column;
  justify-content: center;
  margin-bottom: 4em;
}

[data-terms-and-conditions-container] a {
  color: #29d273;
}

[data-terms-and-conditions-container] .title {
  font-weight: bold;
  padding: 1em 0 0 0;
}

[data-terms-and-conditions-container] .text {
  padding: 1em 0 0 0;
}

[data-terms-and-conditions-container] .button-container {
  padding: 1em 0 0 0;
}

[data-terms-and-conditions-container] .button-container button {
  background-color: var(--lds-colour-primary-500);
  color: white;
  padding: 0.5em 0.75em;
  outline: blue;
  border: none;
  border-radius: 4px;
}

#retry-button {
  display: inline-block;
  color: white;
  background-color: var(--lds-colour-primary-500);
  padding: 12px 24px;
  border-radius: 4px;
  font-weight: 600px;
  margin-top: var(--lds-spacing-s);
}

#retry-button:hover {
  background-color: var(--lds-colour-primary-600);
}

.payment-item-container #continue-to-payment-methods-button {
  background-color: var(--lds-colour-neutral-800);
  transition: opacity background-color 0.5s, color 0.4s;
  border-radius: 0 0 4px 4px;
  width: 100%;
  padding: 1em;
  font-size: 1.3em;
  font-family: "Lato", sans-serif;
  font-style: italic;
  font-weight: normal;
  text-align: center;
  color: white;
  outline: none;
  border: none;
}

#continue-to-payment-methods-button .text {
  font-size: 1.2em;
}

button:disabled {
  opacity: 0.4;
}

legl-payments-accordion {

  .title-margins {
    margin: 1rem 2rem 0 2rem;
  }

  [data-form-row] {
    padding: 0 1rem 1rem 0.5rem;
  }

  .payer-details-text,
  .payment-plan-details-text {
    margin-top: 0;
    margin-bottom: 0.3em;
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
  }

  .payer-details-text.title,
  .payment-plan-details-text.title {
    font-weight: 600;
    color: var(--lds-colour-neutral-800);
  }

  .heading-text {
    color: var(--lds-colour-neutral-900);
  }

  .inactive {
    opacity: 0.2;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    font-size: 1.4em;
  }

  .dropdown-icon {
    border-radius: 25px;
    background-color: transparent;
    transition: background-color 0.4s, color 0.3s;
    padding: 0.1em;
    align-self: center;
    color: rgba(98, 98, 98, 0.65);
    font-size: 1.3em;
  }

  .balance-due {
    background-color: var(--lds-colour-neutral-100);
    font-size: 20px;
    padding: 15px 15px 18px 15px;
    border-radius: 4px;

    >p {
      color: var(--lds-colour-neutral-800);
      text-align: center;
      font-weight: 700;
      margin-bottom: 0;
    }
  }

  .saved-payment-details {
    font-weight: var(--lds-typography-weight-bold);
    font-size: var(--lds-typography-font-size-small);
    color: var(--lds-colour-neutral-800);
    margin-bottom: var(--lds-spacing-m);
  }

  .details-summary {
    width: 100%;
    text-align: left;
    font-size: 1.2em;
    font-weight: 200;
    color: var(--lds-colour-neutral-800);
    padding: 0 2rem 1rem 2rem;
  }

  .payment-plan-info {
    font-weight: var(--lds-typography-weight-normal);
    font-size: var(--lds-typography-font-size-medium);
    margin-bottom: var(--lds-spacing-s);
    padding: var(--lds-spacing-s);
    background-color: var(--lds-colour-neutral-100);
    border-radius: 4px;

    .help-link {
      color: var(--lds-colour-primary-500);
      text-decoration: underline;
    }
  }

  .details-summary .heading-text {
    margin-bottom: var(--lds-spacing-s);

    span {
      font-size: var(--lds-typography-font-size-h3);
      font-weight: var(--lds-typography-weight-bold);
    }
  }

  .details-input {
    padding: 1rem 2rem;
  }

  .details-input .form-field.lds-input {
    margin-bottom: var(--lds-spacing-s);
  }

  .details-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .payment-plan-details-wrapper {
    display: flex;
    flex-wrap: wrap;
    column-gap: var(--lds-spacing-xs);
  }

  .payment-plan-details-wrapper .detail-item {
    @media(min-width: 550px) {
      min-width: 0;
    }

    @media(min-width: 768px) {
      min-width: 0;
    }
  }

  .detail-item {
    min-width: 100%;

    @media(min-width: 550px) {
      margin-right: 10px;
      min-width: calc(50% - 20px);
    }

    @media(min-width: 768px) {
      min-width: 180px;
    }

    margin-bottom: 10px;
  }

  .payer-details-information {
    font-weight: bold;
  }

  .payment-option-text {
    padding: 1em 0 0 0;
    font-family: "Lato", sans-serif;
    font-style: italic;
    font-weight: normal;
    font-size: 18px;
    line-height: 36px;
    color: var(--lds-colour-neutral-700);
    display: flex;
    align-items: center;
    text-align: center;
  }

  .payment-option-text::before,
  .payment-option-text::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid var(--lds-colour-neutral-200);
    padding-top: 15px;
  }

  .payment-option-text::before {
    margin-right: 3rem;
    margin-left: 0.3rem;
  }

  .payment-option-text::after {
    margin-left: 3rem;
    margin-right: 0.3rem;
  }

  &[data-payment-method] .payment-method-item {
    padding: 1em 0 1em 0;
  }

  .invoice-link {
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    color: var(--lds-colour-neutral-800);

    >legl-icon {
      font-size: 18px;
    }
  }

  .shorter-months-copy {
    font-size: var(--lds-typography-font-size-small);
    color: var(--lds-colour-neutral-700);
    margin-bottom: var(--lds-spacing-s);
    text-align: left;
    padding: 0 2rem;
  }

  .payment-plan-schedule {
    width: 100%;
    line-height: 24px;
    font-size: 16px;
    border-spacing: 0 16px;
  }
}

.remove-padding {
  padding: 0;
}

[data-card-fields] [data-card-payment-field] {
  border: none;
  width: 8em;
  background-color: transparent;
}

[data-card-fields] #card-element {
  height: 50px;
  padding: 1em 0 2em 1em;
  border-radius: 6px;
  border: 1px solid var(--lds-colour-neutral-200);
  margin-bottom: 1em;
}

@media screen and (max-width: $tablet-breakpoint) {
  [data-card-fields] #card-element {
    height: 4rem;
    padding: 1.5rem 0 2em 1em;
  }
}

[data-card-fields] #card-errors {
  color: red;
  padding-bottom: 1rem;
}

[data-card-fields] .card-icons {
  text-align: right;
  font-size: 2.4em;
}

[data-card-fields] .card-icons img {
  width: 2.8rem;
  filter: opacity(0.6);
}

[data-confirmation-content] {
  padding: var(--lds-spacing-xl) var(--lds-spacing-m);
}

[data-confirmation-container] {
  margin-top: var(--lds-spacing-xl);
}

[data-confirmation-container] .text-container {
  margin-top: var(--lds-spacing-xl);
}

[data-confirmation-container] .text-container-item:last-child {
  padding: 0 0 1.3em 0;
}

[data-confirmation-container] .outcome-text {
  font-family: var(--lds-typography-family-sans);
  font-weight: var(--lds-typography-weight-bold);
  font-size: var(--lds-typography-font-size-h2);
  line-height: var(--lds-typography-line-height-h2);
  margin: 0 0 var(--lds-spacing-s);
}

[data-confirmation-container] .confirmation-page-top-copy-text {
  margin-top: var(--lds-spacing-xl);
  padding: var(--lds-spacing-s);
  color: var(--lds-colour-neutral-900);
  background-color: var(--lds-colour-neutral-100);
}

[data-confirmation-container] hr {
  margin: 0;
}

[data-confirmation-container] .next-action-text {
  margin: var(--lds-spacing-xl) 0 var(--lds-spacing-s);
}

[data-confirmation-container] .go-to-company-website-button {
  display: inline-block;
  padding: calc(var(--lds-spacing-xxs) + var(--lds-spacing-xs) - 1px) calc(var(--lds-spacing-m) - 1px);
  border: 1px solid var(--lds-colour-neutral-700);
  border-radius: 4px;
}

[data-confirmation-container] .go-to-company-website-button:hover {
  background-color: var(--lds-colour-neutral-100);
}

[data-confirm-card-payment-button] {
  display: flex;
  justify-content: center;
  background: var(--lds-colour-neutral-800);
  color: white;
  font-family: "Lato", sans-serif;
  padding: 1em;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  border: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);
}

[data-confirm-card-payment-button] [data-confirm-card-payment-icon] {
  padding: 0 0.5em 0 0.5em;
}

[data-display-only-on-mobile] {
  display: none;
}

@media only screen and (max-width: 1280px) {
  legl-payments-accordion .name-fields {
    flex-direction: column;
  }

  main [data-payment-container] .payment-title {
    padding: 1.1rem 0 1.5rem 0;
  }
}

@media only screen and (max-width: 1029px) {
  .layout-container {
    margin-left: 0;
    background-color: #fbfafa;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
  }

  [data-display-only-on-mobile] {
    display: block;
  }

  [data-do-not-display-on-mobile] {
    display: none;
  }

  #payment-error-message {
    padding-left: 0;
  }

  #secured-message {
    display: none;
  }

  main [data-payment-container] {
    background-color: transparent;
    padding: 0 2em;
  }

  [data-app="payments"] .payments__wrapper {
    height: 100%;
  }

  #secured-online-payment-bar {
    background-color: white;
    padding: 0;
    border-bottom: 1px solid #75757038;
  }

  main [data-payment-container] .payment-title {
    font-size: 2.1rem;
  }

  legl-payments-accordion .form-field-error {
    line-height: 1em;
  }

  [data-payment-method-overlay] [data-terms-and-conditions-container] {
    height: 100%;
    padding: 3em 2em;
  }

  [data-confirmation-container] .payment-title {
    font-size: 1.6em;
    line-height: 1em;
    padding-top: 1em;
  }

  [data-confirmation-container] .payment-item-container {
    margin-top: 0;
  }

  [data-confirmation-container] .text-container {
    padding: 0;
  }

  [data-confirmation-container] .text-container .outcome-text {
    line-height: 39px;
    color: var(--lds-colour-neutral-700);
  }

  [data-confirmation-container] .next-action-text {
    font-family: "Lato", sans-serif;
    font-style: italic;
    font-size: 0.85em;
    line-height: 26px;
    text-align: center;
    color: var(--lds-colour-neutral-700);
  }

  [data-confirmation-container] [data-confirmation-content] {
    padding: 1.35em;
  }

  .top-bar {
    border-bottom: 1px solid #75757038;
    justify-content: space-between;
  }
}

@media screen and (min-width: $tablet-breakpoint) {
  main [data-payment-container] {
    padding: 1rem 1rem 0 1rem;
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  main {
    margin-bottom: 2rem;
  }

  main [data-payment-container] {
    padding-top: 3rem;
  }
}

@media screen and (max-width: 425px) {
  main [data-payment-container] {
    padding: 1rem 0;
  }

  main [data-payment-container] .payment-title {
    font-size: 1.8rem;
  }

  .loader {
    align-items: start;
    padding: 9rem;
  }
}

.overlay {
  background-color: #000;
  opacity: 0.5;
  position: absolute;
  width: 100%;
  height: 1400px;
  display: none;
  z-index: 13;
}

@media screen and (min-width: 990px) {
  .overlay {
    display: none !important;
  }
}

.loading-spinner {
  display: none;
}

.pending .loading-spinner {
  display: block;
}